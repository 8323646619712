import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from '../redux/store';
import NoPermissionPage from '../pages/NoPermissionPage';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

VendorGuard.propTypes = {
    children: PropTypes.node,
};

export default function VendorGuard({ children }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { rolemodules } = useSelector((state) => state.roleaccess);

    const modules = rolemodules
        ?.flatMap((item) => item?.Modules?.flatMap((item) => item?.childrens?.map((item) => item?.path)))
        .filter((item) => item !== undefined);

    modules?.push(
        '/vendor/dashboard',
        '/vendor/master/shops',
        '/vendor/master/banners',
        '/vendor/master/categories',
        '/vendor/master/reviews',
        '/vendor/master/transactions',
        '/vendor/master/orders',
        '/vendor/master/courses',
        '/vendor/master/products',
        '/vendor/master/achievements',
        '/vendor/master/classes',
        '/vendor/global-settings',
        '/vendor/master/feedbacks',
        '/vendor/master/sales',
        '/vendor/master/cities',
        '/vendor/master/cancle-orders'


    );

    const isRouteAllowed = (route) => {
        if (pathname === '/') {
            navigate(PATH_DASHBOARD.VendorGuard, { replace: true });
            return true;
        }

        const convertedRoute = route?.replace(/s$/, '');
        return pathname.startsWith(route) || pathname.startsWith(`${convertedRoute}/`);
    };

    if (!modules?.some((item) => isRouteAllowed(item))) {
        return <NoPermissionPage />;
    }

    return <>{children}</>;
}
